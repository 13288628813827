import { ServiceFactory } from '@/services/ServiceFactory'
const _securityMeasuresService = ServiceFactory.get('SecurityMeasuresService')
// const _barsService = ServiceFactory.get('BarsService')
export default {
  data () {
    return {
      settings: [],
      securityMeasures: [],
      loading: false,
      secMeasureId: {
        zbSecurityMeasureId: ''
      }
    }
  },
  mounted () {
    this.indexSecurityMeasures()
  },
  methods: {
    async indexSecurityMeasures () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        try {
          this.securityMeasures = await _securityMeasuresService.show(this.$route.params.id)
          // console.log(this.securityMeasures)
          // const getBar = await _barsService.show(this.$store.state.companiesModule.companyData.zbBarId)
          // console.log(this.securityMeasures.securityMeasure)
          // console.log(getBar.bar.securityMeasures[0].zbSecurityMeasureId)
          // this.securityMeasures = this.securityMeasures.securityMeasure.map((v, i) => {
          //   if (getBar.bar.securityMeasures[i]) {
          //     if (v.zbSecurityMeasureId === getBar.bar.securityMeasures[i].zbSecurityMeasureId) {
          //       return {
          //         active: true,
          //         description: v.description,
          //         image: v.image,
          //         name: v.name,
          //         zbSecurityMeasureId: v.zbSecurityMeasureId
          //       }
          //     } else {
          //       return {
          //         active: false,
          //         description: v.description,
          //         image: v.image,
          //         name: v.name,
          //         zbSecurityMeasureId: v.zbSecurityMeasureId
          //       }
          //     }
          //   } else {
          //     return {
          //       active: false,
          //       description: v.description,
          //       image: v.image,
          //       name: v.name,
          //       zbSecurityMeasureId: v.zbSecurityMeasureId
          //     }
          //   }
          // })
        } catch (error) {
          // console.info(error)
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar las medidas de seguridad del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
        }
      } else {
        this.$router.push({ name: 'companies' })
      }
    },
    async checkSecurityMeasure (itemId, value) {
      // console.log(itemId, !value)
      try {
        this.secMeasureId.zbSecurityMeasureId = itemId
        const res = await _securityMeasuresService.editBarMeasure(this.$route.params.id, this.secMeasureId)
        // console.log(res)
        this.indexSecurityMeasures()
        this.$notify({
          type: 'success',
          text: 'Datos modificados correctamente'
        })
      } catch (error) {
        // console.log(error)
        this.$notify({
          type: 'error',
          text: 'Ocurrio un error, intentelo nuevamente'
        })
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
